<template>
  <div class="introduction">
    <h1>{{ msg }}</h1>
    <p>
      I'm currently building this website, so not much to see here yet. <br>
      In the meantime, checkout my LinkedIn 💼 or GitHub 👨🏼‍💻
    </p>

    <a href="https://nl.linkedin.com/in/stevenpostmus" target="blank">
      <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
    </a>
    <a href="https://github.com/SPostmus" target="blank">
      <font-awesome-icon :icon="['fab', 'github']" size="2x" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'IntroPage',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin: 40px 0 0;
}

a {
  color: #130a59; 
  padding: 20px; /* TODO PADDING IS QUICK EN DIRTY GEDAAN */
}
</style>
