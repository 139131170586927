<template>
  <img alt="Steven" src="./assets/images/me.jpg" class="rounded-image">
  <IntroPage msg="Welcome at stevenpostmus.nl! 🎉"/>
  <FooterElement/>
</template>

<script>
import IntroPage from './components/Intro.vue'
import FooterElement from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    IntroPage,
    FooterElement
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.rounded-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
