<template>
    <div class="fluid-container footer">
      <!-- <p class="text-center">Copyright &copy; 2024</p> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'FooterElement',
  };
  </script>
  
  <style scoped>
  .fluid-container.footer {
    padding: 10px 0;
  }
  
  .fluid-container.footer > *:last-child {
    margin-bottom: 0px;
    color: grey;
  }
  </style>
  